\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            codeGroupCd="SAFETY_EQUIP_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="safetyEquipTypeCd"
            label="구분"
            v-model="searchParam.safetyEquipTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            label="관련공정"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="localven"
          title="안전보호장구/비상방제용품 목록"
          tableId="localven"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          rowKey="psiProtectionEpuipmentId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                label="엑셀업로드"
                icon="upload"
                @btnClicked="excelUploadData" />
                  <c-btn v-if="editable" label="등록" icon="add" @btnClicked="equipmentPopup" />
                  <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'washing-safety',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'manageNo' },
          { index: 1, colName: 'manageNo' },
          { index: 2, colName: 'manageNo' },
          { index: 3, colName: 'manageNo' },
          { index: 4, colName: 'manageNo' },
          { index: 5, colName: 'manageNo' },
          { index: 6, colName: 'manageNo' },
          { index: 10, colName: 'manageNo' },
          { index: 11, colName: 'manageNo' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'safetyEquipTypeName',
            field: 'safetyEquipTypeName',
            label: '구분',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'manageNo',
            field: 'manageNo',
            label: '관리번호',
            style: 'width:120px',
            type: 'link',
            align: 'center',
            sortable: true,
          },
          {
            name: 'areaName',
            field: 'areaName',
            label: '지역(공정)',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '품목',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'location',
            field: 'location',
            label: '비치장소',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'detailItemName',
            field: 'detailItemName',
            label: '세부품목',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'performanceStandard',
            field: 'performanceStandard',
            label: '성능 및 규격',
            style: 'width:150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipAmount',
            field: 'equipAmount',
            label: '비치수량',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'checkCycle',
            field: 'checkCycle',
            label: '점검주기',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        safetyEquipTypeCd: null,
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.lbe.protect.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./washingFacilityPlanSafetyDetail.vue"}`);
      this.popupOptions.title = '안전보호구/비상방재물품 상세'; 
      this.popupOptions.param = {
        psiProtectionEpuipmentId: result ? result.psiProtectionEpuipmentId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    excelUploadData() {
      this.popupOptions.title = '안전보호구/비상방재물품 목록 업로드';
      this.popupOptions.target = () => import(`${'./washingFacilityPlanSafetyExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          // item.plantCd = this.searchParam.plantCd;
          item.SysRevision = 1;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
        })

        this.$http.url = transactionConfig.psi.lbe.protect.insert.url + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
  }
};
</script>
